const domain = 'https://engieapppreview.oktapreview.com';

const config = {
  url: domain,
  issuer: `${domain}/oauth2/default`,
  redirectUri: window.location.origin,
  clientId: '0oa264phm6s2dbeME0i7',
};

export default config;
